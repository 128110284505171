<template>
  <div class="content">
    <h1>
    <span>S</span>
    <span>i</span>
    <span>n</span>
    <span>o</span>
    <span>m</span>
    <span>a</span>
  </h1>

  <div class="sinoma-loader">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  </div>
</template>
<script>
import website from "@/config/website";
export default {
  data() {
    return {
      domain: process.env.VUE_APP_BASE_ENTERPRISEWECHATURL
    }
  },
  created() {
    var test = (function () {
      var onSuccess = function (geoipResponse) {/* There's no guarantee that a successful response object* has any particular property, so we need to code defensively. */
        if (!geoipResponse.country.iso_code) {
          return;
        }/* ISO country codes are in upper case. */
        var code = geoipResponse.country.iso_code.toLowerCase();
        if(code !== 'cn'){
          this.domain = process.env.VUE_APP_BASE_ENTERPRISEWECHATURL
        }
      };
      var onError = function (error) {
      };
      return function () {
        geoip2.country(onSuccess, onError);
      };
    }());
    if(process.env.NODE_ENV === 'production'){
      test();
    }
  },
  mounted() {
    this.redirect();
  },
  methods: {
    redirect() {
      // 重定向 URL
      const redirect_uri = encodeURI(`${this.domain}/#/wxRedirect`);
      // 授权链接 URL

      const login_url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${process.env.VUE_APP_BASE_CORPID}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base#wechat_redirect`
      // 向授权链接 URL 跳转
      window.location.href = login_url;
    },
  }
}
</script>
<style lang="scss" scoped>
.content {
  /* 取消页面内外边距 */
  margin: 0;
  padding: 0;
  /* 100%窗口高度 */
  height: 100vh;
  /* 弹性布局 水平、垂直居中 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
$font: "Montserrat", sans-serif;

$blue: #4285f4;
$red: #db4437;
$yellow: #f4b400;
$green: #0f9d58;
$colors-list: $blue $red $yellow $green;

.sinoma-loader {
	display: block;
	span {
		display: inline-block;
		margin-top: 10px;
		height: 20px;
		width: 20px;
		border-radius: 50%;
		&:not(:first-child) {
			margin-left: 10px;
		}
	}
	@each $current-color in $colors-list {
		$i: index($colors-list, $current-color);
		$t: $i * -0.25;
		span:nth-child(#{$i}) {
			background: $current-color;
			animation: move 1s ease-in-out (#{$t}s) infinite alternate;
		}
	}
}

@keyframes move {
	from {
		transform: translateY(-10px);
	}
	to {
		transform: translateY(5px);
	}
}

h1 {
	font-family: $font;
	font-size: 4em;
	text-align: center;
	letter-spacing: -8px;
	margin-top: 0;
	span {
    padding: 0 5px;
		&:first-child {
			color: $blue;
		}
		&:nth-child(2) {
			color: $red;
		}
		&:nth-child(3) {
			color: $yellow;
		}
		&:nth-child(4) {
			color: $blue;
		}
		&:nth-child(5) {
			color: $green;
		}
		&:last-child {
			color: $red;
			transform: rotate(-20deg);
			display: inline-block;
		}
	}
}

</style>
