<template>
  <div class="Box">
    <div class="box">
      <!-- <div>time1：{{ time1 }}</div>
    <div>time11：{{ time11 }}</div>
    <div>time2：{{ time2 }}</div> -->
    </div>
    <div class="dialog" v-if="dialogTableVisible">
      <div class="dbox">
        <div class="title">提示</div>
        <div class="body">
          <div class="txt">{{ txt }}</div>
          <div class="btn" @click="queren">确认</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWeixinSignature } from "@/api/home/wx";
import { getWinCode } from "@/api/home/wx";
// import wx from "weixin-js-sdk"
import dayjs from "dayjs";
import website from "@/config/website";
export default {
  data() {
    return {
      dialogTableVisible: false,
      redirect_uri: "",
      code: "",
      obj:{},
      loading: false,
      msg: "",
      time1: "",
      time11: "",
      time2: "",
      readytime22: "",
      time3: "",
      meg4: "",
      wx: "",
      jWeixin: "",
      txt: "暂无次账户信息, 无法进入",
    };
  },
  mounted() {
    let {type,id} = this.$route.query;
    this.obj = {
      type:type,
      id:id
    }
    this.getOauthUser();
  },
  created() {
  },
  methods: {
    //
    queren() {
      this.dialogTableVisible = false;
      wx.closeWindow();
    },
    getOauthUser() {
      let that = this;
      let url = window.location.href;
      that.redirect_uri = url.split("#")[0];
      // let objUrl = url.split("#")[1];
      let p = url.split("?")[1];
      let query = new URLSearchParams(p);
      that.code = query.get("code");
      // that.obj = JSON.parse(decodeURIComponent(this.getUrlParams(objUrl).obj))
      if (that.redirect_uri.indexOf("%23") > -1) {
        that.redirect_uri = that.redirect_uri.replace("%23", "%2523");
      }
      getWeixinSignature(encodeURIComponent(that.redirect_uri), "app").then(
        (res) => {
          if (res.data.code === 200) {
            that.openDefaultBrowser(res.data.data);
          }
        }
      );
    },
    getUrlParams(url) {
      // 通过 ? 分割获取后面的参数字符串
      let urlStr = url.split('?')[1]
      // 创建空对象存储参数
      let obj = {};
      // 再通过 & 将每一个参数单独分割出来
      let paramsArr = urlStr.split('&')
      for (let i = 0, len = paramsArr.length; i < len; i++) {
        // 再通过 = 将每一个参数分割为 key:value 的形式
        let arr = paramsArr[i].split('=')
        obj[arr[0]] = arr[1];
      }
      return obj
    },
    openDefaultBrowser(info) {
      // 初始化qywx-js-sdk
      let that = this;
      // console.log("info", info);
      getWinCode(that.code).then((res) => {
        //存储登陆信息
        if (res.status === 200 && res.data) {
          // console.log("res.data", res.data);
          if (res.data.access_token) {
            that.openapplite(info, res.data.access_token);
          } else {
            that.txt = "暂无次账户信息, 无法进入";
            that.dialogTableVisible = true;
          }
        } else {
          that.txt = "服务链接失败";
          that.dialogTableVisible = true;
        }
      });
    },
    openapplite(info, access_token) {
      let that = this;
      // that.time11 = dayjs(new Date()).format("YYYY-DD-MM hh:mm:ss");
      wx.agentConfig({
        corpid: process.env.VUE_APP_BASE_CORPID,
        agentid: process.env.VUE_APP_BASE_AGENTID,
        timestamp: info.timestamp,
        nonceStr: info.noncestr,
        signature: info.signature,
        jsApiList: ["launchMiniprogram", "openDefaultBrowser"], // 需要使用的JS接口列表
        success: function (res) {
          wx.invoke(
            "launchMiniprogram",
            {
              appid: process.env.VUE_APP_BASE_APPID, // 需跳转的小程序appid
              path: "pages/login/login-account?access_token=" + access_token + "&page=" + "&type=" + that.obj.type + "&id=" + that.obj.id, // 所需跳转的小程序内页面路径及参数。非必填|| page 为需要跳到小程序的页面路径
            },
            (result) => {
              that.msg = result;
              setTimeout(() => {
                // window.close();
                wx.closeWindow();
              }, 1000);
              if (result.err_msg == "openDefaultBrowser:ok") {
                // 打开默认浏览器成功后的回调函数
                console.log("打开浏览器~~~result", result);
              }
            }
          );
        },
        fail: function (res) {
          // this.meg5 = res;
          // console.log(res);
          that.fail = res;
          if (res.errMsg.indexOf("function not exist") > -1) {
            alert("版本过低请升级");
          }
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 9;
}
.dialog {
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba($color: #000000, $alpha: 0.5);
}
.dbox {
  width: 80%;
  background: #fff;
  border-radius: 10px;
  text-align: center;
  .title {
    font-size: 20px;
    padding-top: 10px;
    margin-bottom: 10px;
  }
}
.txt {
  padding: 10px;
}
.btn {
  padding: 10px;
  font-size: 16px;
  color: #1989fa;
  border-top: 1px solid #eee;
  border-radius: 0 0 10px 10px;
}
.btn:active {
  background: #dcdee0;
}
</style>
