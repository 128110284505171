var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Box" }, [
    _c("div", { staticClass: "box" }),
    _vm.dialogTableVisible
      ? _c("div", { staticClass: "dialog" }, [
          _c("div", { staticClass: "dbox" }, [
            _c("div", { staticClass: "title" }, [_vm._v("提示")]),
            _c("div", { staticClass: "body" }, [
              _c("div", { staticClass: "txt" }, [_vm._v(_vm._s(_vm.txt))]),
              _c("div", { staticClass: "btn", on: { click: _vm.queren } }, [
                _vm._v("确认"),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }