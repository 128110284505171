var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("h1", [
        _c("span", [_vm._v("S")]),
        _c("span", [_vm._v("i")]),
        _c("span", [_vm._v("n")]),
        _c("span", [_vm._v("o")]),
        _c("span", [_vm._v("m")]),
        _c("span", [_vm._v("a")]),
      ]),
      _c("div", { staticClass: "sinoma-loader" }, [
        _c("span"),
        _c("span"),
        _c("span"),
        _c("span"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }