<template>
  <div></div>
</template>
<script>
import { getWeixinSignature } from "@/api/home/wx";
import website from "@/config/website";
export default {
  data() {
    return {
      redirect_uri: "",
      domain: process.env.VUE_APP_BASE_ENTERPRISEWECHATURL,
      os: {},
      code: "",
    };
  },
  mounted() {
    this.os = (function () {
      var ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet =
          /(?:iPad|PlayBook)/.test(ua) ||
          (isAndroid && !/(?:Mobile)/.test(ua)) ||
          (isFireFox && /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian;
      return {
        isTablet: isTablet,
        isPhone: isPhone,
        isAndroid: isAndroid,
        isPc: isPc,
      };
    })();
    this.getOauthUser();
  },
  created() {
    var test = (function () {
      var onSuccess = function (geoipResponse) {
        /* There's no guarantee that a successful response object* has any particular property, so we need to code defensively. */
        if (!geoipResponse.country.iso_code) {
          return;
        } /* ISO country codes are in upper case. */
        var code = geoipResponse.country.iso_code.toLowerCase();
        if (code !== "cn") {
          this.domain = process.env.VUE_APP_BASE_ENTERPRISEWECHATURL;
        }
      };
      var onError = function (error) {};
      return function () {
        geoip2.country(onSuccess, onError);
      };
    })();
    if (process.env.NODE_ENV === "production") {
      test();
    }
  },
  methods: {
    getOauthUser() {
      let that = this;
      let url = window.location.href;
      console.log("url:" + url);
      let p = url.split("?")[1];
      let query = new URLSearchParams(p);
      that.code = query.get("code");
      //mac safari跳转url有问题，需要特殊处理
      if (url.indexOf("%23wechat_redirect") != -1) {
        url = url.replace("%23wechat_redirect", "");
        window.location.href = url;
      }
      let urls = url.split("#");
      that.redirect_uri = urls[0];
      // if (this.os.isPc) {
        getWeixinSignature(encodeURIComponent(that.redirect_uri), "pc").then(
          (res) => {
            if (res.data.code === 200) {
              that.openPCDefaultBrowser(res.data.data);
            }
          }
        );
      // } else {
      //   getWeixinSignature(encodeURIComponent(that.redirect_uri), "app").then(
      //     (res) => {
      //       if (res.data.code === 200) {
      //         that.openDefaultBrowser(res.data.data);
      //       }
      //     }
      //   );
      // }
    },
    openPCDefaultBrowser(info) {
      // 初始化qywx-js-sdk
      wx.config({
        beta: true,
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: process.env.VUE_APP_BASE_CORPID,
        timestamp: info.timestamp,
        nonceStr: info.noncestr,
        signature: info.signature,
        jsApiList: ["openDefaultBrowser", "closeWindow"], // 需要使用的JS接口列表
      });
      const redirect_uri_encode =
        process.env.NODE_ENV === "production"
          ? encodeURIComponent(`${this.domain}/#/wxDefaultBrower`)
          : encodeURIComponent(`${this.domain}/#/wxDefaultBrower`);
      wx.error((res) => {
        console.log("config信息验证失败", res);
        return;
      });
      // 在需要调用企业微信打开默认浏览器的地方调用
      wx.ready(() => {
        wx.invoke(
          "openDefaultBrowser",
          {
            url: `https://open.weixin.qq.com/connect/oauth2/authorize?appid=` + process.env.VUE_APP_BASE_CORPID + `&redirect_uri=${redirect_uri_encode}&response_type=code&scope=snsapi_base&agentid=` + process.env.VUE_APP_BASE_AGENTID + `#wechat_redirect`,
          },
          (result) => {
            if (result.err_msg == "openDefaultBrowser:ok") {
              // 打开默认浏览器成功后的回调函数
              wx.closeWindow();
              wx.invoke("closeWindow");
            }
          }
        );
      });
    },
    openDefaultBrowser(info) {
      let that = this;
      wx.agentConfig({
        corpid: process.env.VUE_APP_BASE_CORPID,
        agentid: process.env.VUE_APP_BASE_AGENTID,
        timestamp: info.timestamp,
        nonceStr: info.noncestr,
        signature: info.signature,
        jsApiList: ["launchMiniprogram", "openDefaultBrowser"], // 需要使用的JS接口列表
        success: function (res) {
          wx.invoke(
            "launchMiniprogram",
            {
              appid: process.env.VUE_APP_BASE_APPID, // 需跳转的小程序appid
              path: "pages/login/login-account?code=" + that.code +'&type=xcx', // 所需跳转的小程序内页面路径及参数。非必填|| page 为需要跳到小程序的页面路径
            },
            (result) => {
              if (result.err_msg == "launchMiniprogram:ok") {
                // 打开默认浏览器成功后的回调函数
                console.log("打开浏览器~~~result", result);
                wx.closeWindow();
              }
            }
          );
        },
        fail: function (res) {
          if (res.errMsg.indexOf("function not exist") > -1) {
            alert("版本过低请升级");
          }
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
