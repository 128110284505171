<template>
  <div></div>
</template>
<script>
import { getWinCode } from "@/api/home/wx";
import { getUserInfo } from "@/api/system/user";

export default {
  data() {
    return {
      code: "",
      userInfoObj: null,
    };
  },
  mounted() {
    this.getOauthUser();
  },
  created() {},
  methods: {
    getOauthUser() {
      let that = this;
      let url = window.location.href;
      let p = url.split("?")[1];
      let query = new URLSearchParams(p);
      let code = query.get("code");
      console.log(code, "-----");
      getWinCode(code).then(async (res) => {
        //存储登陆信息
        if (res.status === 200 && res.data) {
          that.$store.commit("SET_TOKEN", res.data.access_token);
          that.$store.commit("SET_REFRESH_TOKEN", res.data.refresh_token);
          that.$store.commit("SET_TENANT_ID", res.data.tenant_id);
          that.$store.commit("SET_USER_INFO", res.data);
          that.$store.commit("DEL_ALL_TAG");
          that.$store.commit("CLEAR_LOCK");
          console.log('接口调用成功------------');
          that.$router.push("/cip/index");
          await getUserInfo().then((res) => {
            if (res.data.code == 200) {
              this.userInfoObj = { ...res.data.data };
              this.$store.commit("SET_USERINFOOBJ", res.data.data);
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
