<template>
  <div class="content">
<!--    <h1>-->
<!--      <span>S</span>-->
<!--      <span>i</span>-->
<!--      <span>n</span>-->
<!--      <span>o</span>-->
<!--      <span>m</span>-->
<!--      <span>a</span>-->
<!--    </h1>-->

<!--    <div class="sinoma-loader">-->
<!--      <span></span>-->
<!--      <span></span>-->
<!--      <span></span>-->
<!--      <span></span>-->
<!--    </div>-->
  </div>
</template>
<script>
  import{loginByCode,authorize } from "@/api/user";
  export default {
    name: 'unifiedPortalLogin',
    data() {
      return {}
    },
    created() {
    },
    mounted() {
      this.redirect();
    },
    methods: {
      redirect() {
        let url = window.location.href;
        let p = url.split("?")[1];
        let query = new URLSearchParams(p);
        let taskId = query.get('code') && query.get('code').split('#')[0]

        if (!taskId){
          let baseUrl = process.env.NODE_ENV === 'production' ? this.website.enterpriseOaUrl : this.website.enterpriseOaUrl
          let redirect_uri = process.env.NODE_ENV === 'production' ?  this.website.enterpriseWechatUrl: 'http://hse.sinoma.com.cn'
          let client_id = process.env.NODE_ENV === 'production' ? '818a609d01820001' : '818a609d01820001'
          window.location.href = `${process.env.VUE_APP_BASE_ENTERPRISEOAURL}/cas/oauth2.0/authorize?client_id=${client_id}&redirect_uri=${process.env.VUE_APP_BASE_ENTERPRISEWECHATURL}/#/unifiedPortalLogin`
          return
        }

        console.log(url,'----', p, '----',taskId)
        loginByCode({code: taskId}).then(res=>{
          console.log(res ,'loginByCodeloginByCode')
            const userInfo = res.data.data;
            this.qrCodeTimer = null;
            this.$store.commit("SET_TOKEN", userInfo.access_token);
            this.$store.commit("SET_REFRESH_TOKEN", userInfo.refresh_token);
            this.$store.commit("SET_TENANT_ID", userInfo.tenant_id);
            this.$store.commit("SET_USER_INFO", userInfo);
            this.$store.commit("DEL_ALL_TAG");
            this.$store.commit("CLEAR_LOCK");
            this.$store.commit("SET_SHAKE_DOWN_SHOW", 'show');
            this.$router.push({ path: "/cip" });
        })
      },
    }
  }
</script>
<style lang="scss" scoped>
  .content {
    /* 取消页面内外边距 */
    margin: 0;
    padding: 0;
    /* 100%窗口高度 */
    height: 100vh;
    /* 弹性布局 水平、垂直居中 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  $font: "Montserrat", sans-serif;

  $blue: #4285f4;
  $red: #db4437;
  $yellow: #f4b400;
  $green: #0f9d58;
  $colors-list: $blue $red $yellow $green;

  .sinoma-loader {
    display: block;
    span {
      display: inline-block;
      margin-top: 10px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
    @each $current-color in $colors-list {
      $i: index($colors-list, $current-color);
      $t: $i * -0.25;
      span:nth-child(#{$i}) {
        background: $current-color;
        animation: move 1s ease-in-out (#{$t}s) infinite alternate;
      }
    }
  }

  @keyframes move {
    from {
      transform: translateY(-10px);
    }
    to {
      transform: translateY(5px);
    }
  }

  h1 {
    font-family: $font;
    font-size: 4em;
    text-align: center;
    letter-spacing: -8px;
    margin-top: 0;
    span {
      padding: 0 5px;
      &:first-child {
        color: $blue;
      }
      &:nth-child(2) {
        color: $red;
      }
      &:nth-child(3) {
        color: $yellow;
      }
      &:nth-child(4) {
        color: $blue;
      }
      &:nth-child(5) {
        color: $green;
      }
      &:last-child {
        color: $red;
        transform: rotate(-20deg);
        display: inline-block;
      }
    }
  }

</style>
