<template>
  <div></div>
</template>
<script>
import website from "@/config/website";

export default {
  data() {
    return {
      domain: process.env.VUE_APP_BASE_ENTERPRISEWECHATURL
    };
  },
  created() {},
  mounted() {
    let {type,id} = this.$route.query;
    let obj = {
      type:type,
      id:id
    }
    this.redirect(obj);
  },
  methods: {
    redirect(obj) {
      // 当前企业的 corp_id
      // 重定向 URL
      const redirect_uri = encodeURI(`${this.domain}/#/wxGoApplet?obj=` + JSON.stringify(obj));
      // 授权链接 URL
      const login_url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${process.env.VUE_APP_BASE_CORPID}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
      // 向授权链接 URL 跳转
      window.location = login_url;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  /* 取消页面内外边距 */
  margin: 0;
  padding: 0;
  /* 100%窗口高度 */
  height: 100vh;
  /* 弹性布局 水平、垂直居中 */
  display: flex;
  justify-content: center;
  align-items: center;
  /* 渐变背景 */
  background: linear-gradient(200deg, #b5aee4, #505285);
}

.loading {
  width: 86px;
  height: 196px;
  /* 相对定位 */
  position: relative;
  /* 弹性布局 */
  display: flex;
  /* 将元素垂直显示 */
  flex-direction: column;
  /* 将元素靠边对齐 */
  justify-content: space-between;
  align-items: center;
  animation: rotating 2s linear infinite;
}

/* 沙漏上下两个容器 */
.top,
.bottom {
  width: 70px;
  height: 70px;
  border-style: solid;
  border-color: #dcdcdc;
  border-width: 4px 4px 12px 12px;
  border-radius: 50% 100% 50% 30%;
  position: relative;
  /* 这里把溢出的部分隐藏 */
  overflow: hidden;
}

.top {
  /* 旋转-45度 */
  transform: rotate(-45deg);
}

.bottom {
  /* 旋转135度 */
  transform: rotate(135deg);
}

/* 容器里的沙 */
.top::before,
.bottom::before {
  content: "";
  /* 绝对定位 */
  position: absolute;
  /* inherit表示继承父元素（这里指宽高） */
  width: inherit;
  height: inherit;
  background-color: #cabbe9;
  /* 执行动画，先设置动画的参数，暂时不指定动画名称，我们在下面再来指定 */
  animation: 2s linear infinite;
}

.top::before {
  /* 通过设置圆角来改变沙的形状 */
  border-radius: 0 100% 0 0;
  /* 执行指定的动画 */
  animation-name: drop-sand;
}

.bottom::before {
  /* 通过设置圆角来改变沙的形状 */
  border-radius: 0 0 0 25%;
  /* 这里我们将下面的沙移出可视范围 */
  transform: translate(50px, -50px);
  /* 执行指定的动画 */
  animation-name: fill-sand;
}

/* 到这里我们还少了个沙子流下来的效果 */
/* 添加流下的元素 */
.loading::after {
  content: "";
  width: 5px;
  height: 96px;
  background-color: #cabbe9;
  /* 绝对定位 */
  position: absolute;
  top: 20px;
  /* 执行动画：动画 时长 线性的 无限次播放 */
  animation: flow 2s linear infinite;
}

/* 接下来我们来定义动画 */
/* 落沙动画 */
@keyframes drop-sand {
  to {
    transform: translate(-50px, 50px);
  }
}

/* 填沙动画 */
@keyframes fill-sand {
  to {
    transform: translate(0, 0);
  }
}

/* 沙流下动画 */
@keyframes flow {
  10%,
  100% {
    transform: translateY(64px);
  }
}

/* 最后再来一个沙漏旋转的动画 */
@keyframes rotating {
  0%,
  90% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
</style>
